import React, { FC, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { SanityPost } from 'web/graphql-types'
import Box from '@material-ui/core/Box'
import Grid from '@/atoms/Grid'
import withWidth from '@material-ui/core/withWidth'
import SanityImage from '@/atoms/SanityImage'
import theme from '../../styles/MUItheme'
import { Typography } from '@material-ui/core'
import { Link } from '@/atoms/link'
import { getUri } from '@/utils/routing'
import { useWindowDimensions } from '@/hooks/useWindow'

const PostLink = styled(Link)`
  display: block;
  width: 100vw;
  text-decoration: none !important;

  :hover {
    cursor: pointer;
  }

  &:nth-child(odd) {
    background-color: ${theme.palette.background.default};
    p,
    h2 {
      color: ${theme.palette.text.primary};
    }
  }

  &:nth-child(even) {
    background-color: ${theme.palette.secondary.main};
    p,
    h2 {
      color: ${theme.palette.background.paper};
    }
  }
`

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
`
const StyledTextBox = styled(Box)`
  &:hover {
    p,
    h2 {
      color: black !important;
    }
  }
  p {
    line-height: 1.25;
    padding-bottom: 0 !important;
  }
  h2 {
    line-height: 1.08;
  }
`

type BlogPostProps = {
  post: SanityPost
  aspectRatio: number
  idx: number
}

const BlogPost: FC<BlogPostProps> = ({ post, idx, width, aspectRatio }) => {
  const { width: useWidth } = useWindowDimensions()

  const isEvenRow = idx % 2 == 0
  const contentCol = (
    <StyledTextBox
      className={'styled-text-boxes-post-overview'}
      height="100%"
      width="100%"
      maxWidth="56rem"
      display="flex"
      alignContent="center"
      justifyContent="center"
      flexDirection="column"
      p={{ xs: 3 }}
    >
      <Box pb={1.5}>
        <Typography component="h2" variant="h2" align="center">
          {post.title}
        </Typography>
      </Box>
      {post?.meta?.metaDescription && (
        <Typography paragraph={true} align="center">
          {post?.meta?.metaDescription}
        </Typography>
      )}
    </StyledTextBox>
  )
  const imgCol = post.meta?.ogImage && (
    <SanityImage image={post.meta?.ogImage} width={1024}></SanityImage>
  )
  const isMobile = width === 'xs'

  const postHeight = () => {
    if(!useWidth) {
      console.log(isMobile)
      if (!isMobile) 
        return '34vw'
      else
        return '80vw'
    }
    if (isMobile) return `${Math.floor((useWidth || 1) / aspectRatio)}px`
    return `${Math.floor((useWidth || 1) / 2 / aspectRatio)}px`
  }

  return (
    <PostLink to={getUri(post?.slug?.current, 'post')}>
      <Grid container>
        <Grid xs={12} sm={6}>
          <StyledBox height={postHeight()}>
            {isEvenRow && !isMobile ? contentCol : imgCol}
          </StyledBox>
        </Grid>
        <Grid xs={12} sm={6}>
          <StyledBox height={postHeight()}>
            {isEvenRow && !isMobile ? imgCol : contentCol}
          </StyledBox>
        </Grid>
      </Grid>
    </PostLink>
  )
}

export default withWidth()(BlogPost)
