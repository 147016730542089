import React, { FC, useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '@/utils/helpers'
import { SanityPage, SanityPost, SanityPostConnection } from 'web/graphql-types'
import { NavigationContext } from '@/contexts/NavigationContext'
import Layout from '@/containers/Layout'
import BlogIndex from '@/components/BlogIndex'
import { getUri } from '@/utils/routing'

interface Props {
  data: {
    sanityPage: SanityPage
    allSanityPost: SanityPostConnection
  }
}

const Blog: FC<Props> = props => {
  const allSanityPost = props.data.allSanityPost

  const { backButton, route } = props.data.sanityPage

  const [allPosts] = useState<SanityPost[]>(
    mapEdgesToNodes(allSanityPost).sort(
      (a: SanityPost, b: SanityPost) => (a.order || 0) - (b.order || 0)
    )
  )
  const { setRoute, setBackButton } = useContext(NavigationContext)

  useEffect(() => {
    setBackButton(backButton || false)
    setRoute(getUri(route?.slug?.current, route?._type || undefined))
  }, [])

  return (
    <Layout meta={props.data.sanityPage.meta}>
      <BlogIndex allPosts={allPosts} />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    sanityPage(title: { eq: "Blog" }) {
      id
      title
      meta {
        ...metaData
      }
      backButton
      route {
        ... on SanityPage {
          _type
          slug {
            current
          }
        }
        ... on SanityPost {
          _type
          slug {
            current
          }
        }
        ... on SanityStore {
          _type
          slug {
            current
          }
        }
      }
      contentModules {
        ...contentModulesData
      }
    }
    allSanityPost {
      edges {
        node {
          id
          slug {
            current
          }
          title
          order
          _updatedAt
          meta {
            ogImage {
              ...Image
            }
            metaDescription
          }
        }
      }
    }
  }
`
