import React, { FC, useEffect, useState } from 'react'
import { SanityPost } from 'web/graphql-types'
import Grid from '@/atoms/Grid'
import BlogPost from './BlogPost'
import { useWindowDimensions } from '@/hooks/useWindow'

type BlogIndexProps = {
  allPosts: SanityPost[]
}

const BlogIndex: FC<BlogIndexProps> = ({ allPosts }) => {
  const { width } = useWindowDimensions()

  const [aspectRatio, setAspectRatio] = useState(1.47)

  useEffect(() => {
    const boxes = [
      ...document.getElementsByClassName('styled-text-boxes-post-overview')
    ]
    const h6 = boxes
      .map(b => b.querySelector('h2'))
      .map(h6 => h6?.clientHeight || 0)

    const p = boxes
      .map(p => p.querySelector('p'))
      .map(p => p?.clientHeight || 0)

    const sumH6P = []
    for (let i = 0; i < h6.length; i++) {
      sumH6P.push(h6[i] + p[i])
    }

    const maxTextHeight = Math.max(...sumH6P)

    let aR
    if (width && width < 1025) aR = width / (maxTextHeight + 100)
    else aR = width && width / 2 / (maxTextHeight + 100)

    aR && aR < 1.47 && setAspectRatio(aR)
  }, [width])

  return (
    <Grid container maxWidth="unset">
      {allPosts.map((post, idx) => (
        <BlogPost post={post} key={idx} idx={idx} aspectRatio={aspectRatio} />
      ))}
    </Grid>
  )
}

export default BlogIndex
